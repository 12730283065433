import React from "react";
import { Link } from "react-router-dom";
import {
  INTRO_APP,
  SIGN_IN_APP,
  APP_HOME,
  CTA_SITE,
} from "../../routes/external_routes";
import { ROUTES } from "../../routes/routes.config";

import { makeStyles, Drawer, Grid, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BusinessIcon from "@material-ui/icons/Business";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";

import DefaultButton from "../buttons/DefaultButton";
import SquareButton from "../buttons/SquareButton";
import SociiLogo from "../../assets/logos/named-orange.svg";

import { getAnalytics, logEvent } from "firebase/analytics";
import { Home } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "35px 0 0 35px",
    padding: theme.spacing(3),
    background: theme.palette.bg,
    maxWidth: 330,
    "@media (max-width: 400px)": {
      maxWidth: "85vw",
    },
  },
  gridContainer: {
    height: "100%",
  },
  logo: {
    width: "100%",
    maxWidth: 250,
  },
  gridBtns: {
    maxWidth: 300,
  },
}));

export default function MenuDrawer(props) {
  const { open, onClose } = props;

  const classes = useStyles();

  const handleClose = (event) => {
    handleLogEvent(event);
    onClose();
  };

  const handleLogEvent = (event) => {
    const analytics = getAnalytics();
    logEvent(analytics, event);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => handleClose("close")}
      transitionDuration={400}
      classes={{
        root: "zIndexUp",
        paper: classes.paper,
      }}
    >
      <Grid
        container
        justifyContent="center"
        className={classes.gridContainer}
        spacing={2}
      >
        <Grid item xs={12}>
          <IconButton onClick={() => handleClose("click_drawer_close")}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <img src={SociiLogo} className={classes.logo} alt="Socii Logo" />
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          className={classes.gridBtns}
          container
          spacing={2}
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={4}>
            <SquareButton
              href={ROUTES.HOME}
              onClick={() => handleClose("click_drawer_p1")}
              text="Início"
              icon={<Home fontSize="large" color="primary" />}
            />
          </Grid>
          <Grid item xs={4}>
            <SquareButton
              href={ROUTES.SOBRE_NOS}
              onClick={() => handleClose("click_drawer_p2")}
              text="Sobre nós"
              icon={<BusinessIcon fontSize="large" color="primary" />}
            />
          </Grid>
          <Grid item xs={4}>
            <SquareButton
              href={ROUTES.CLIENTES}
              onClick={() => handleClose("click_drawer_p3")}
              text="Clientes"
              icon={
                <SupervisedUserCircleIcon fontSize="large" color="primary" />
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container alignContent="flex-end">
          <DefaultButton
            isBigger={true}
            variant="contained"
            color="primary"
            href={SIGN_IN_APP}
            onClick={() => handleClose("click_drawer_log")}
            style={{ marginBottom: ".75rem" }}
          >
            Acessar app
          </DefaultButton>
          <DefaultButton
            isBigger={true}
            variant="contained"
            isGreen={true}
            color="primary"
            onClick={() => handleClose("click_drawer_sign")}
            href={CTA_SITE}
          >
            Fale conosco
          </DefaultButton>
        </Grid>
      </Grid>
    </Drawer>
  );
}
