import React from "react";
import { CTA_SITE, INTRO_APP, SIGN_IN_APP } from "../../routes/external_routes";
import { ROUTES } from "../../routes/routes.config";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

import {
  useTheme,
  makeStyles,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";

import SociiLogo from "../../assets/logos/named-orange.svg";
import MenuIcon from "../../assets/menu.svg";

import DefaultButton from "../buttons/DefaultButton";

import MenuDrawer from "../drawers/MenuDrawer";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "0 10%",
    display: "flex",
    justifyContent: "space-evenly",
    minHeight: 73,
    position: "relative",
    "@media (max-width: 1200px)": {
      padding: "0 5%",
    },
  },
  textButton: {
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: "400",
    fontSize: "16px",
  },
  selected: {
    fontWeight: "600",
  },
  logo: {
    width: "40px",
  },
  logo2: {
    width: 150,
    [theme.breakpoints.down("xs")]: {
      width: 137,
    },
  },
  btns: {
    width: "22vw",
    minWidth: 300,
    display: "flex",
    justifyContent: "space-evenly",
  },
  btn: {
    width: "40%",
    marginRight: 10,
    minWidth: 138,
  },
  menuBtn: {
    position: "absolute",
    right: "5%",
  },
  menuIcon: {
    width: 25,
  },
}));

function Appbar({ route }) {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleClickOpen = () => {
    const analytics = getAnalytics();
    logEvent(analytics, "click_drawer_open");
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleLogEvent = (event) => {
    const analytics = getAnalytics();
    logEvent(analytics, event);
  };

  const checkValue = (r) => {
    return route === r ? classes.selected : null;
  };

  return (
    <AppBar elevation={1} color="default">
      <Toolbar className={classes.toolbar}>
        {matchesUpMd ? (
          <>
            <img src={SociiLogo} width={150} alt="Socii Logo" />
            <Button
              component={Link}
              to={ROUTES.HOME}
              className={`${classes.textButton} ${checkValue(
                ROUTES.HOME
              )}  default-button`}
              onClick={() => handleLogEvent("click_menu_p1")}
            >
              Início
            </Button>
            <Button
              component={Link}
              to={ROUTES.SOBRE_NOS}
              className={`${classes.textButton} ${checkValue(
                ROUTES.SOBRE_NOS
              )}  default-button`}
              onClick={() => handleLogEvent("click_menu_p2")}
            >
              Sobre nós
            </Button>
            <Button
              component={Link}
              to={ROUTES.CLIENTES}
              className={`${classes.textButton} ${checkValue(
                ROUTES.CLIENTES
              )} default-button`}
              onClick={() => handleLogEvent("click_menu_p3")}
            >
              Clientes
            </Button>

            <div className={classes.btns}>
              <DefaultButton
                variant="contained"
                isGreen={true}
                color="primary"
                onClick={() => handleLogEvent("click_menu_whatsapp")}
                href={CTA_SITE}
                className={classes.btn}
              >
                Fale conosco
              </DefaultButton>
              <DefaultButton
                variant="contained"
                color="primary"
                href={SIGN_IN_APP}
                className={classes.btn}
                onClick={() => handleLogEvent("click_menu_log")}
              >
                Acessar app
              </DefaultButton>
            </div>
          </>
        ) : (
          <>
            <IconButton
              onClick={handleClickOpen}
              className={`default-icon-button ${classes.menuBtn}`}
            >
              <img src={MenuIcon} alt="Menu" />
            </IconButton>

            <img src={SociiLogo} width={200} alt="Logo Socii" />
            <MenuDrawer open={openDrawer} onClose={handleClose} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
