import React from "react";
import { Switch, Route } from "react-router-dom";
import { routeConfig } from "./routes.config";
import { MainLayout } from "../components/layout/MainLayout";

const Routes = () => {
  return (
    <Switch>
      {routeConfig.map(
        ({ path, component: Component, redirect, exact, layout, props }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            render={(routeProps) => {
              if (redirect) {
                window.location.href = redirect;
                return null;
              }

              const routeComponent = <Component {...routeProps} {...props} />;

              return layout ? (
                <MainLayout route={routeProps.location.pathname}>
                  {routeComponent}
                </MainLayout>
              ) : (
                routeComponent
              );
            }}
          />
        )
      )}
    </Switch>
  );
};

export default Routes;
