import { useMemo } from "react";
import { ROUTES } from "../routes/routes.config";

// Routes where Appbar should be hidden
const ROUTES_WITHOUT_APPBAR = [
  ROUTES.RIW,
  ROUTES.ANALYSIS,
  ROUTES.RM3,
  ROUTES.RM3_ANALISE,
  ROUTES.RM3_ANALISE_MIN,
  ROUTES.CONTABILIZEI_LP,
  ROUTES.CONTABILIZEI_LP_2,
  ROUTES.RM_CONTABILIZEI_LP,
  ROUTES.RM_CONTABILIZEI_LP_2,
  ROUTES.VENTIUR_LP,
  ROUTES.RM_VENTIUR_LP,
  ROUTES.NEW_LEAD,
  ROUTES.LEAD,
  ROUTES.REFERRAL_LP,
  ROUTES.MAR_CONTAB_LP,
];

export const useAppbarVisibility = (route) => {
  return useMemo(() => {
    // Check if current route is in the exclusion list
    const shouldHide = ROUTES_WITHOUT_APPBAR.some((path) =>
      route.startsWith(path)
    );

    return !shouldHide;
  }, [route]);
};
