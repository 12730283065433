import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { history } from "./store/history.js";
import "./external.css";
import "./index.css";

import CookiesAlert from "./components/alerts/CookiesAlert.jsx";
import FirebaseAnalytics from "./firebase/FirebaseAnalytics";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@material-ui/core";
import { getAnalytics, logEvent } from "firebase/analytics";

import { hotjar } from "react-hotjar";
import LogRocket from "logrocket";
import Routes from "./routes/index.jsx";

function App() {
  const [cookies, setCookie] = useCookies(["acceptCookies"]);
  const isDev = window.location.hostname === "localhost";

  useEffect(() => {
    if (!isDev) {
      hotjar.initialize(2815934, 6);
      LogRocket.init("uqtvp1/sociilaw");
    }

    history.listen((location) => {
      if (!isDev) hotjar.stateChange(location.pathname);
      window.scrollTo({ top: 0 });
    });
  }, [isDev]);

  const handleCloseCookiesAlert = () => {
    const analytics = getAnalytics();
    logEvent(analytics, "click_close_cookies_alert");
    setCookie("acceptCookies", true);
  };

  return (
    <Router history={history}>
      <FirebaseAnalytics />
      <React.Suspense fallback={<CircularProgress color="primary" />}>
        <Routes />
      </React.Suspense>
      <CookiesAlert
        open={cookies["acceptCookies"] !== "true"}
        handleClose={handleCloseCookiesAlert}
      />
    </Router>
  );
}

export default App;
