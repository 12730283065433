import { useMemo } from "react";
import { ROUTES } from "../routes/routes.config";
import { useMediaQuery } from "@material-ui/core";

// Routes where Appbar should be hidden
const ROUTES_WITHOUT_APPBAR = [ROUTES.ANALYSIS];

export const useMetaChatVisibility = (route) => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return useMemo(() => {
    // Check if current route is in the exclusion list
    const shouldHide = ROUTES_WITHOUT_APPBAR.some((path) =>
      route.startsWith(path)
    );

    return !shouldHide && !matchesXS;
  }, [route, matchesXS]);
};
