import { lazy } from "react";

// Lazy load components
const HomePage = lazy(() => import("../pages/home"));
const SobreNosPage = lazy(() => import("../pages/sobre-nos"));
const ClientesPage = lazy(() => import("../pages/clientes"));

const RegistroMarcaPage = lazy(() =>
  import("../pages/landing-pages/deprecated/RegistroMarca")
);
const RegistroMarcaV2Page = lazy(() =>
  import("../pages/landing-pages/deprecated/RegistroMarcaV2")
);
const RegistroMarcaV3Page = lazy(() =>
  import("../pages/landing-pages/deprecated/RegistroMarcaV3")
);
const RegistroMarcaV4Page = lazy(() =>
  import("../pages/landing-pages/deprecated/RegistroMarcaV4")
);
const ContratosInvestimentoPage = lazy(() =>
  import("../pages/landing-pages/contrato-investimento")
);
const RiwPage = lazy(() => import("../pages/landing-pages/events/RIW"));
const AnalysisPage = lazy(() => import("../pages/landing-pages/Analysis"));
const NewLeadPage = lazy(() => import("../pages/Management/NewLead"));
const LeadPage = lazy(() => import("../pages/Management/Lead"));

const AnalysisAdsPage = lazy(() =>
  import("../pages/landing-pages/analysis-ads")
);
const RegistrationAdsPage = lazy(() =>
  import("../pages/landing-pages/registration-ads")
);
const RegistrationContabPage = lazy(() =>
  import("../pages/landing-pages/registration-contab")
);
const RegistrationVentiurPage = lazy(() =>
  import("../pages/landing-pages/registration-ventiur")
);
const ContractsContabPage = lazy(() =>
  import("../pages/landing-pages/contracts-contab")
);
const ContractsVentiurPage = lazy(() =>
  import("../pages/landing-pages/contracts-ventiur")
);
const ReferralsPage = lazy(() => import("../pages/landing-pages/referrals"));
const MarContabPage = lazy(() => import("../pages/landing-pages/mar-contab"));

export const ROUTES = {
  HOME: "/",
  SOBRE_NOS: "/sobre-nos",
  CLIENTES: "/clientes",
  INSTITUTIONAL: "/institucional",
  FEATURES: "/features",
  SOCII_PLUS: "/socii+",
  ESPECIALISTS: "/especialistas",
  PLANS: "/planos",
  REGISTRO_MARCA_LP: "/rm1",
  REGISTRO_MARCA_LP_V2: "/rm2",
  REGISTRO_MARCA_LP_V3: "/rm0",
  REGISTRO_MARCA_LP_V4: "/rm1Analise",
  RM3: "/rm3",
  RM3_ANALISE: "/rm3Analise",
  RM3_ANALISE_MIN: "/rm3analise",
  CONTABILIZEI_LP: "/contabilizei",
  CONTABILIZEI_LP_2: "/contabilizeipromo",
  RM_CONTABILIZEI_LP: "/rmcontabilizei",
  RM_CONTABILIZEI_LP_2: "/rmcontabilizeipromo",
  MAR_CONTAB_LP: "/mar-contab",
  VENTIUR_LP: "/ventiur",
  RM_VENTIUR_LP: "/rmventiur",
  REFERRAL_LP: "/indicacao",
  NEW_LEAD: "/novo-lead",
  LEAD: "/gerenciar-leads",
  CONTRATOS_INVESTIMENTO_LP: "/contratosdeinvestimento",
  RIW: "/riw",
  APP: "/app",
  ANALYSIS: "/analise-saude-juridica",
};

export const routeConfig = [
  {
    path: ROUTES.HOME,
    component: HomePage,
    exact: true,
    layout: true,
  },
  {
    path: ROUTES.SOBRE_NOS,
    component: SobreNosPage,
    layout: true,
  },
  {
    path: ROUTES.CLIENTES,
    component: ClientesPage,
    layout: true,
  },
  {
    path: ROUTES.REGISTRO_MARCA_LP_V2,
    component: RegistroMarcaV2Page,
    layout: true,
  },
  {
    path: ROUTES.REGISTRO_MARCA_LP_V3,
    component: RegistroMarcaV3Page,
    layout: true,
  },
  {
    path: ROUTES.REGISTRO_MARCA_LP_V4,
    component: RegistroMarcaV4Page,
    layout: true,
  },
  {
    path: ROUTES.CONTRATOS_INVESTIMENTO_LP,
    component: ContratosInvestimentoPage,
    layout: true,
  },
  {
    path: ROUTES.RIW,
    component: RiwPage,
    layout: true,
  },
  {
    path: ROUTES.ANALYSIS,
    component: AnalysisPage,
    layout: true,
  },
  {
    path: ROUTES.NEW_LEAD,
    component: NewLeadPage,
    layout: true,
  },
  {
    path: ROUTES.LEAD,
    component: LeadPage,
    layout: true,
  },
  {
    path: ROUTES.RM3,
    component: RegistrationAdsPage,
    layout: true,
  },
  {
    path: ROUTES.RM3_ANALISE,
    component: AnalysisAdsPage,
    layout: true,
  },
  {
    path: ROUTES.RM3_ANALISE_MIN,
    component: AnalysisAdsPage,
    layout: true,
  },
  {
    path: ROUTES.RM_CONTABILIZEI_LP,
    component: RegistrationContabPage,
    layout: true,
  },
  {
    path: ROUTES.RM_CONTABILIZEI_LP_2,
    component: RegistrationContabPage,
    layout: true,
  },
  {
    path: ROUTES.RM_VENTIUR_LP,
    component: RegistrationVentiurPage,
    layout: true,
  },
  {
    path: ROUTES.CONTABILIZEI_LP,
    component: ContractsContabPage,
    layout: true,
  },
  {
    path: ROUTES.CONTABILIZEI_LP_2,
    component: ContractsContabPage,
    layout: true,
  },
  {
    path: ROUTES.VENTIUR_LP,
    component: ContractsVentiurPage,
    layout: true,
  },

  {
    path: ROUTES.REFERRAL_LP,
    component: ReferralsPage,
    layout: true,
  },
  {
    path: ROUTES.MAR_CONTAB_LP,
    component: MarContabPage,
    layout: true,
  },
  {
    path: ROUTES.APP,
    redirect: "https://app.sociilaw.com",
  },
];
