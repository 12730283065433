import React from "react";
import { useAppbarVisibility } from "../../hooks/useAppbarVisibility";
import { useMetaChatVisibility } from "../../hooks/useMetaChatVisibility";
import MetaChat from "../MetaChat";
import Appbar from "../Appbar";

export const MainLayout = ({ children, route }) => {
  const shouldShowAppbar = useAppbarVisibility(route);
  const shouldShowMetaChat = useMetaChatVisibility(route);

  return (
    <>
      {shouldShowMetaChat && <MetaChat />}
      {shouldShowAppbar && <Appbar route={route} />}
      <main className={shouldShowAppbar ? "root-with-padding" : ""}>
        {children}
      </main>
    </>
  );
};
